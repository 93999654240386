/* ===========================================================
 * pt.js
 * Portuguese translation for Trumbowyg
 * http://alex-d.github.com/Trumbowyg
 * ===========================================================
 * Author : Ramiro Varandas Jr (ramirovjr)
 *          Twitter : @ramirovjnr
 *          Website : about.me/ramirovjnr
 *          Github : https://github.com/ramirovjr
 */
jQuery.trumbowyg.langs.pt={viewHTML:"Ver HTML",formatting:"Formatar",p:"Paragráfo",blockquote:"Citação",code:"Código",header:"Título",bold:"Negrito",italic:"Itálico",strikethrough:"Suprimir",underline:"Sublinhado",strong:"Forte",em:"Ênfase",del:"Apagar",unorderedList:"Lista não ordenada",orderedList:"Liste ordenada",insertImage:"Inserir imagem",insertVideo:"Inserir vídeo",link:"Link",createLink:"Criar um link",unlink:"Remover link",justifyLeft:"Alinhar a esquerda",justifyCenter:"Centralizar",justifyRight:"Alinhar a direita",justifyFull:"Justificar",horizontalRule:"Inserir separador horizontal",fullscreen:"Tela cheia",close:"Fechar",submit:"Enviar",reset:"Limpar",required:"Obrigatório",description:"Descrição",title:"Título",text:"Texto"};